<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="pathwayData === undefined">
      <h4 class="alert-heading">Error fetching pathway data</h4>
      <div class="alert-body">
        No pathway found with this pathway id. Check
        <b-link class="alert-link" :to="{ name: 'apps-roster-pathway-list' }"> Pathway List </b-link>
        for other pathways.
      </div>
    </b-alert>

    <template v-if="pathwayData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="4" lg="3" md="12">
          <pathway-view-pathway-info-card :pathway-data="pathwayData" />
        </b-col>
        <b-col cols="12" md="12" xl="8" lg="9">
          <b-card no-body class="mb-0">
            <div class="m-2">
              <!-- Table Top -->
              <b-row>
                <!-- Per Page -->
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                  <label>Show</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                  />
                  <label>entries</label>
                </b-col>

                <!-- Search -->
                <b-col cols="12" md="6">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button
                      variant="primary"
                      :to="{ name: 'apps-roster-session-builder', query: { pathway: $route.params.id } }"
                    >
                      <span class="text-nowrap">Create Session</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>

            <b-table
              ref="refSessionListTable"
              class="position-relative"
              :items="fetchSessions"
              :fields="tableColumns"
              responsive
              primary-key="id"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              :sort-by-formatted="true"
              show-empty
              empty-text="No matching records found"
            >
              <!-- Column: Session -->
              <template #cell(name)="data">
                <b-link
                  :to="{ name: 'apps-roster-session-view', params: { id: data.item.id } }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ data.item.name }}
                </b-link>
                <small class="text-muted">{{ (data.item.courses || []).length }} total courses</small>
              </template>

              <!-- Column: Live Courses -->
              <template #cell(live_courses)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{
                    data.item.courses.filter((course) => course.status === 'live').length
                  }}</span>
                </div>
              </template>
              <!-- Column: Complete Courses -->
              <template #cell(completedCourses)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{
                    data.item.courses.filter((courses) => courses.status === 'completed').length
                  }}</span>
                </div>
              </template>
              <!-- Column: Total Students -->
              <template #cell(profiles)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{ data.item.profiles.length }}</span>
                </div>
              </template>

              <!-- Column: Visibility -->
              <template #cell(visibility)="data">
                <b-badge
                  pill
                  :variant="`light-${resolveSessionVisibilityVariant(data.item.visibility)}`"
                  class="text-capitalize"
                >
                  {{ data.item.visibility.split('_').join(' ') }}
                </b-badge>
              </template>

              <!-- Column: Status -->
              <template #cell(status)="data">
                <b-badge pill :variant="`light-${resolveSessionStatusVariant(data.item.status)}`" class="text-capitalize">
                  {{ data.item.status.split('_').join(' ') }}
                </b-badge>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                  </template>
                  <b-dropdown-item :to="{ name: 'apps-roster-session-view', params: { id: data.item.id } }">
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">View</span>
                  </b-dropdown-item>

                  <b-dropdown-item :to="{ name: 'apps-roster-session-edit', params: { id: data.item.id } }">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item @click="archiveSession(data.item.id)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Archive</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted"
                    >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
                  >
                </b-col>
                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalSessions"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row v-if="false">
        <b-col cols="12" lg="6">
          <pathway-view-pathway-timeline-card />
        </b-col>
        <b-col cols="12" lg="6">
          <pathway-view-pathway-permissions-card />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTable,
  BFormInput,
  BButton,
  BCard,
  BMedia,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BDropdownDivider,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue';
import rosterStoreModule from '../rosterStoreModule';
import PathwayViewPathwayInfoCard from './PathwayViewUserInfoCard';
import PathwayViewPathwayPlanCard from './PathwayViewUserPlanCard.vue';
import PathwayViewPathwayTimelineCard from './PathwayViewUserTimelineCard.vue';
import PathwayViewPathwayPermissionsCard from './PathwayViewUserPermissionsCard.vue';
import SessionList from '@/views/apps/roster/sessions-list/SessionList';
import usePathwaySessionsList from './usePathwaySessionsList';

export default {
  components: {
    SessionList,
    BRow,
    BCol,
    BAlert,
    BLink,
    BTable,

    BCard,
    BFormInput,
    BButton,
    BMedia,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BDropdownDivider,

    vSelect,

    // Local Components
    PathwayViewPathwayInfoCard,
    PathwayViewPathwayPlanCard,
    PathwayViewPathwayTimelineCard,
    PathwayViewPathwayPermissionsCard,

    InvoiceList,
  },
  methods: {
    archiveSession(sessionId) {
      store
        .dispatch('app-roster/updateSession', {
          sessionId,
          sessionData: { status: 'archived' },
        })
        .then((response) => {
          this.refetchData();
        });
    },
  },
  setup() {
    const pathwayData = ref(null);

    const PATHWAY_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(PATHWAY_APP_STORE_MODULE_NAME))
      store.registerModule(PATHWAY_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PATHWAY_APP_STORE_MODULE_NAME)) store.unregisterModule(PATHWAY_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch('app-roster/fetchPathway', {
        id: router.currentRoute.params.id,
        queryParams: { populate: 'sessions' },
      })
      .then((response) => {
        pathwayData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          pathwayData.value = undefined;
        }
      });

    const {
      fetchSessions,
      tableColumns,
      perPage,
      currentPage,
      totalSessions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSessionListTable,
      refetchData,

      statusOptions,

      // UI
      resolveSessionVisibilityVariant,
      resolveSessionStatusVariant,

      // Extra Filters
      visibilityFilter,
      statusFilter,
    } = usePathwaySessionsList(pathwayData);

    return {
      pathwayData,

      fetchSessions,
      tableColumns,
      perPage,
      currentPage,
      totalSessions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSessionListTable,
      refetchData,

      statusOptions,

      // UI
      resolveSessionVisibilityVariant,
      resolveSessionStatusVariant,

      // Extra Filters
      visibilityFilter,
      statusFilter,
    };
  },
};
</script>

<style></style>
