<template>
  <b-card
    :img-src="require(`@/assets/images/banner/banner-42-${skin || 'dark'}.jpg`)"
    img-alt="Profile Cover Photo"
    img-top
    img-height="100"
    class="card-profile"
  >
    <div class="profile-image-wrapper">
      <div class="profile-image p-0">
        <b-avatar
          :src="pathwayData.avatar"
          :text="avatarText(pathwayData.name)"
          :variant="`light-${resolvePathwayStatusVariant(pathwayData.status)}`"
          size="104px"
        />
      </div>
    </div>
    <h3>{{ pathwayData.name }}</h3>
    <h6 class="text-muted">
      <b-badge class="profile-badge m-0" variant="light-primary">
        {{ title(pathwayData.status + ` | ` + pathwayData.visibility) }}
      </b-badge>
    </h6>
    <hr class="mb-2" />

    <!-- follower projects rank -->
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h6 class="text-muted font-weight-bolder">Total Students</h6>
        <h3 class="mb-0">
          {{ pathwayData.sessions.map((session) => session.profiles.length).reduce((a, b) => a + b, 0) }}
        </h3>
      </div>
      <div>
        <h6 class="text-muted font-weight-bolder">Total Sessions</h6>
        <h3 class="mb-0">
          {{ pathwayData.sessions.length }}
        </h3>
      </div>
      <div>
        <h6 class="text-muted font-weight-bolder">Total Courses</h6>
        <h3 class="mb-0">
          {{ pathwayData.sessions.map((session) => session.courses.length).reduce((a, b) => a + b, 0) }}
        </h3>
      </div>
    </div>

    <b-row class="mt-1">
      <b-button :to="{ name: 'apps-roster-pathway-edit', params: { id: pathwayData.id } }" variant="primary" size="sm" block>
        Edit Pathway
      </b-button>
    </b-row>
    <!--/ follower projects rank -->
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol, BBadge } from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import usePathwaysList from '../pathways-list/usePathwaysList';
import { title } from '@core/utils/filter';
import usAppConfig from '@core/app-config/useAppConfig';

const { skin } = usAppConfig();

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
  },
  props: {
    pathwayData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolvePathwayStatusVariant } = usePathwaysList();
    return {
      avatarText,
      resolvePathwayStatusVariant,
      title,
      skin,
    };
  },
};
</script>

<style></style>
